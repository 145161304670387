import { mapState } from 'vuex';

export default {
  data() {
    return {
      acceptTheTermsOfUse: false,
      changeTypePassword: false,
      content: {
        id: -1,
        loggedIn: false,
        username: "",
        rank: "user",
        email: "",
        created: "01-01-1970 00:00:00",
        last_connected: "01-01-1970 00:00:00",
        captchaPassed: false,
      },
      extra_user_info: {
        gender: "male",
        firstName: "",
        lastName: "",
        phone: "",
        age: "1985-01-01",
        adress: "",
        citycode: "",
        city: "",
      },
      passwd1: "",
      passwd2: "",
      captcha: "",
      check: {
        username: false,
        email: false,
        password: false,
        firstName: false,
        lastName: false,
        phone: false,
        adress: false,
        citycode: false,
        city: false,
      },
    };
  },
  beforeDestroy() {
    this.acceptTheTermsOfUse = this.changeTypePassword = this.content = this.extra_user_info = this.passwd1 = this.passwd2 = this.captcha = undefined;
  },
  computed: {
    ...mapState(["account"]),
    get_type_password() {
      return this.changeTypePassword ? "text" : "password";
    },
    acceptTermsOfUse() {
      return this.acceptTheTermsOfUse ? "success" : "danger";
    },
    checkRegister() {
      const checker = [
        "username",
        "email",
        "password",
        "firstName",
        "lastName",
        "phone",
        "adress",
        "citycode",
        "city",
      ];
      for (const d of checker) {
        if (this.check[d]) continue;
        return true;
      }
      return false;
    },
    checkPersonnalChange() {
      const checker = [
        "email",
        "firstName",
        "lastName",
        "phone",
        "adress",
        "citycode",
        "city",
      ];
      for (const d of checker) {
        if (this.check[d]) continue;
        return true;
      }
      return false;
    },
    checkAnonymousForm() {
      const checker = ["email", "firstName", "lastName", "phone"];
      for (const d of checker) {
        if (this.check[d]) continue;
        return true;
      }
      return false;
    },
  },
  methods: {
    async loadContent() {
      this.$store.dispatch("axios/http_reading", true);
      // const data = await this.$root.axios("get", "api/personnal/info");
      await this.$root.handleGetRequest("api/personnal/info", false);
      const data = await this.$root.processGetRequest();

      if (data.content && data.extra_user_info) {
        this.content = data.content;
        this.extra_user_info = data.extra_user_info;
      } else {
        console.error("api/personnal/info inaccessible");
      }
    },
    async renewCaptcha() {
      // await this.$root.axios("put", "api/captcha/change");
      await this.$store.dispatch("axios/renewCaptcha", { vm: this });
    },
    dataChanged() {
      if (!this.$root.isExtraInfoChanged(this.extra_user_info)) return true;
      else if (!this.$root.isContentChanged(this.content)) return true;
      else if (this.passwd1 === "" || this.passwd2 === "") return false;
      else return true;
    },
    passwordRandomGenerator(size = 10, specialChar = ".&@") {
      const Obj = {
        passwdGen: "",
        passwdChar:
          "azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBN0123456789",
        indexSpecialChar: this.$tools.getRandomInt(size),
      };

      for (let idx = 0; idx < parseInt(size); idx++) {
        Obj.passwdGen +=
          idx === Obj.indexSpecialChar
            ? specialChar[this.$tools.getRandomInt(specialChar.length)]
            : Obj.passwdChar[this.$tools.getRandomInt(Obj.passwdChar.length)];
      }

      return Obj.passwdGen;
    },
    generatePassword(size = 8) {
      const password = this.passwordRandomGenerator(size);
      if (!this.check_password(password, password))
        return this.generatePassword(size + 1);
      this.passwd1 = this.passwd2 = password;
      this.changeTypePassword = true;
    },
    check_password(password1, password2) {
      if (typeof password1 === "string" && password1.length === 0) return null;
      const require_check = {
        matches: password1 === password2,
        size: password1.length,
        uppercase: 0,
        lowercase: 0,
        number: 0,
      };

      for (let i = 0; i < require_check["size"]; i++) {
        if (password1[i] >= "a" && password1[i] <= "z") {
          require_check["lowercase"]++;
        } else if (password1[i] >= "A" && password1[i] <= "Z") {
          require_check["uppercase"]++;
        } else if (password1[i] >= "0" && password1[i] <= "9") {
          require_check["number"]++;
        }
      }

      if (
        !require_check["matches"] ||
        require_check["size"] < 8 ||
        require_check["uppercase"] < 1 ||
        require_check["lowercase"] < 2 ||
        require_check["number"] < 2
      ) {
        this.check["password"] = false;
        return false;
      }

      this.check["password"] = true;
      return true;
    },
    check_size(value, max_length = 5, min_length = 0) {
      if (typeof value !== "string") {
        return false;
      } else if (value.length < min_length) {
        return false;
      } else if (value.length > max_length) {
        return false;
      } else {
        return true;
      }
    },
    check_username(value) {
      if (typeof value === "string" && value.length === 0) return null;
      let res = this.check_size(value, 30, 4);
      this.check["username"] = res;
      return res;
    },
    check_mail(mail) {
      if (typeof mail === "string" && mail.length === 0) return null;
      if (!this.check_size(mail, 80)) {
        this.check["email"] = false;
        return false;
      }

      for (let j = 1; j < mail.length; j++) {
        if (mail.charAt(j) == "@") {
          if (j < mail.length - 4) {
            for (var k = j; k < mail.length - 2; k++) {
              if (mail.charAt(k) == ".") {
                this.check["email"] = true;
                return true;
              }
            }
          }
        }
      }
      this.check["email"] = false;
      return false;
    },
    check_firstname(value) {
      if (typeof value === "string" && value.length === 0) return null;
      let res = this.check_size(value, 50, 2);
      this.check["firstName"] = res;
      return res;
    },
    check_lastname(value) {
      if (typeof value === "string" && value.length === 0) return null;
      let res = this.check_size(value, 50, 2);
      this.check["lastName"] = res;
      return res;
    },
    check_phone(phone) {
      if (typeof phone === "string" && phone.length === 0) return null;
      const regex = new RegExp(/^(01|02|03|04|05|06|07|08)[0-9]{8}/gi);
      const res = regex.test(phone) && phone.length < 11 ? true : false;
      this.check["phone"] = res;
      return res;
    },
    check_adress(value) {
      if (typeof value === "string" && value.length === 0) return null;
      let res = this.check_size(value, 255, 5);
      this.check["adress"] = res;
      return res;
    },
    check_citycode(value) {
      if (typeof value === "string" && value.length === 0) return null;
      let res = this.check_size(value, 5, 5);
      this.check["citycode"] = res;
      return res;
    },
    check_city(value) {
      if (typeof value === "string" && value.length === 0) return null;
      let res = this.check_size(value, 50, 2);
      this.check["city"] = res;
      return res;
    },
    formatter_lowercase(value) {
      if (typeof value !== "string") return "";
      return value.toLowerCase();
    },
    formatter_uppercase(value) {
      if (typeof value !== "string") return "";
      return value.toUpperCase();
    },
    formatter_capitalize(value) {
      if (typeof value !== "string") return "";
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
