<template>
  <b-container class="bg-dark fontStandard">
    <b-form>
      <b-row>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-input-group>
              <b-button variant="secondary" class="text-left" block>
                <strong>Compte N°</strong>
                <b-badge variant="light" align="left" class="ml-1">
                  <strong>{{ supervisor.id }}</strong>
                </b-badge>
              </b-button>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            Compte Utilisateur
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-user"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="content_username"
                class="form-control"
                placeholder="Identifiant"
                type="text"
                v-model="supervisor.user"
                :formatter="formatter_lowercase"
                :state="check_username(supervisor.user)"
                disabled
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            Adresse email
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-envelope"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="content_email"
                class="form-control"
                placeholder="Adresse email"
                autocomplete="off"
                type="email"
                v-model="supervisor.email"
                :state="check_mail(supervisor.email)"
              />

              <b-form-invalid-feedback
                :state="check_mail(supervisor.email)"
              >Indiquez une adresse email correcte</b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            Droits d'accès
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-cog"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <select class="form-control" v-model="supervisor.rank">
                <option value="root">Super admin</option>
                <option value="admin">Admin</option>
                <option value="user">Utilisateur</option>
              </select>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            Création
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i v-b-popover.hover.right="supervisor.created" class="fas fa-eye"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-datepicker
                v-model="supervisor.created"
                dark
                no-flip
                hide-header
                readonly
                disabled
              />
            </b-input-group>
          </b-form-group>
          <b-form-group>
            Dérnière connexion
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i v-b-popover.hover.right="supervisor.last_connected" class="fas fa-eye"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-datepicker
                v-model="supervisor.last_connected"
                dark
                no-flip
                hide-header
                readonly
                disabled
              />
            </b-input-group>
          </b-form-group>
          <b-form-group>
            Dernier transfert de données
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i v-b-popover.hover.right="supervisor.last_access_data" class="fas fa-eye"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-datepicker
                v-model="supervisor.last_access_data"
                dark
                no-flip
                hide-header
                readonly
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-address-book"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <select class="form-control" v-model="supervisor.gender">
                <option value="male" selected>Homme</option>
                <option value="female">Femme</option>
              </select>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-address-book"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_firstname"
                class="form-control"
                placeholder="Prénom"
                type="text"
                v-model="supervisor.firstName"
                :formatter="formatter_capitalize"
                maxlength="50"
                :state="check_firstname(supervisor.firstName)"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-address-book"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_lastname"
                class="form-control"
                placeholder="Nom"
                type="text"
                v-model="supervisor.lastName"
                :formatter="formatter_uppercase"
                maxlength="50"
                :state="check_lastname(supervisor.lastName)"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-phone"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_phone"
                class="form-control"
                placeholder="N° téléphone"
                type="text"
                v-model="supervisor.phone"
                :state="check_phone(supervisor.phone)"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-birthday-cake"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-datepicker
                id="content_birthday"
                v-model="supervisor.age"
                dark
                no-flip
                hide-header
                readonly
                disabled
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            Adresse / Code postal / Ville:
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-address-card"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_address"
                class="form-control"
                placeholder="Adresse"
                type="text"
                v-model="supervisor.adress"
                maxlength="255"
                :state="check_adress(supervisor.adress)"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-address-card"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_citycode"
                class="form-control"
                placeholder="Code postal"
                type="text"
                v-model="supervisor.citycode"
                maxlength="10"
                :state="check_citycode(supervisor.citycode)"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-address-card"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_city"
                class="form-control"
                placeholder="Ville"
                type="text"
                v-model="supervisor.city"
                :formatter="formatter_uppercase"
                maxlength="50"
                :state="check_city(supervisor.city)"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group v-if="account.axios_data && account.axios_data.picture">
            <b-input-group>
              <b-img
                width="500"
                fluid
                thumbnail
                center
                rounded="top"
                block
                :src="account.axios_data.picture"
                alt="captcha"
              />
              <b-input-group-prepend>
                <b-button @click="renewCaptcha()" variant="primary">
                  <i class="fas fa-undo-alt" />
                </b-button>
              </b-input-group-prepend>
              <b-input
                class="form-control"
                placeholder="Captcha"
                type="text"
                v-model="captcha"
                @click="captcha = ''"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-button
              @click="change_enable_account()"
              :variant="supervisor.enable === '1' ? 'secondary' : 'primary'"
              :disabled="!canDisableAction(supervisor)"
              block
            >
              <i
                :class="[
                    'fas',
                    supervisor.enable === '1' ? 'fa-user-slash' : 'fa-user'
                  ]"
              ></i>
              <strong>
                &nbsp;{{
                supervisor.enable === "1" ? "Désactiver" : "Activer"
                }}
                ce compte
              </strong>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-button type="submit" variant="success" @click="adm_change_account" block>
              <i class="fas fa-user-edit" /> Modifier
            </b-button>
          </b-form-group>
        </b-col>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-button
              type="submit"
              variant="danger"
              @click="$root.$emit('bv::hide::modal', 'info-modal')"
              block
            >
              <i class="fas fa-times" /> Fermer cette fenetre
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Form from "@/mixins/Form";

export default {
  mixins: [Form],
  props: {
    items: {
      type: Object,
      default: {
        id: "",
        enable: "",
        user: "",
        rank: "",
        email: "",
        created: "",
        last_connected: "",
        loggedIn: "",
        last_access_data: "",
        gender: "",
        firstName: "",
        lastName: "",
        phone: "",
        age: "",
        adress: "",
        citycode: "",
        city: "",
      },
    },
  },
  data() {
    return {
      supervisor: this.items,
    };
  },
  beforeDestroy() {
    this.supervisor = undefined;
  },
  computed: {
    ...mapState(["account"]),
    ...mapGetters(["admin/allow_this_tab", "admin/allow_this_action"]),
  },
  methods: {
    async change_enable_account() {
      const supervisor_enable = this.supervisor.enable === "1" ? "0" : "1";

      const uri =
        supervisor_enable === "1"
          ? "admin/account/enable/true"
          : "admin/account/enable/false";

      await this.$root.axios("put", uri, {
        id: this.supervisor.id,
      });

      const axios = this.account.axios_data;
      if (
        axios &&
        axios.admin_manager_response === "admin_change_successfull"
      ) {
        this.supervisor.enable = supervisor_enable;
      }

      this.completeCommit();
    },
    async adm_change_account(e) {
      e.preventDefault();

      const Obj = {
        id: this.supervisor.id,

        rank: this.supervisor.rank,
        email: this.supervisor.email,

        gender: this.supervisor.gender,
        firstName: this.supervisor.firstName,
        lastName: this.supervisor.lastName,
        phone: this.supervisor.phone,
        age: this.supervisor.age,
        adress: this.supervisor.adress,
        citycode: this.supervisor.citycode,
        city: this.supervisor.city,

        captcha: this.captcha,
      };

      await this.$root.axios("put", "admin/account/change", Obj);

      this.completeCommit();
    },
    completeCommit() {
      const axios = this.account.axios_data;

      if (
        axios &&
        axios.admin_manager_response === "admin_change_successfull" &&
        axios.check_input.length === 0
      ) {
        this.$root.showToast(
          `Modification`,
          `Modifications effectuées`,
          "info",
          2000
        );

        // this.$parent.$parent.$parent.$refs.table.refresh();
        // TO DO recharger les données dans le modal ?
      }
    },
    canDisableAction(action = "") {
      if (
        typeof action.rank !== "undefined" &&
        typeof action.id !== "undefined" &&
        this.$root.getContent()["id"]
      ) {
        const rank = action.rank;

        let response = false;
        if (parseInt(action.id) === this.$root.getContent()["id"]) {
          response = false;
        } else if (rank === "root") {
          response = this["admin/allow_this_action"]("account_disable_root")
            ? true
            : false;
        } else {
          response = this["admin/allow_this_action"]("account_disable")
            ? true
            : false;
        }
        return response;
      } else {
        return false;
      }
    },
  },
};
</script>
